<template>
  <div>
    <div v-if="isVisibleAllContent">
      <div v-if="breadcrumbList" class="breadcrumb-container">
        <ul>
          <li
            v-for="(breadcrumb, index) in breadcrumbList"
            :key="index"
            @click="routeTo(index)"
            :class="{ linked: !!breadcrumb.link }"
            v-show="breadcrumb.name"
          >
            <span> {{ breadcrumb.name }}</span>
          </li>
        </ul>
      </div>
      <div id="divFeedDetailWrapper" class="content-layout-fixer">
        <div class="feed-item-container" v-if="contentDetail">
          <div v-if="contentDetail.feed.videoURL" class="feed-top-video-container">
            <BrandVimeoPlayer
              class="video-wrapper"
              :id="contentDetail.feed.videoURL"
              ref="vimeoPlayer"
              :data="{ embedVideo: contentDetail.feed.videoURL, title: contentDetail.feed.title }"
            >
            </BrandVimeoPlayer>
            <div class="feed-image-bottom">
              <VueBadge :radius="20" class="overlay feed-badge overlay-left">
                <LikeButton
                  class="btn-feed-like"
                  :status="contentDetail.feed.liked"
                  :count.sync="contentDetail.feed.likeCount"
                  :contentId="contentDetail.feed.feedId"
                  :contentName="contentDetail.feed.title"
                />
                <span v-if="contentDetail.feed.likeCount">{{ contentDetail.feed.likeCount }}</span>
              </VueBadge>
              <VueBadge :radius="20" class="overlay feed-badge overlay-right">
                {{ contentDetail.feed.category.categoryText }}
              </VueBadge>
            </div>
          </div>
          <BrandMediaContentBox
            class="feed-media"
            v-if="media.content && !contentDetail.feed.videoURL"
            :mediaSrc="media.content"
            allowfullscreen
            :aspectRatios="media.ratio"
            :isListelistImage="contentDetail.feed.sourceType === 2"
          >
            <div class="feed-image-bottom">
              <VueBadge :radius="20" class="overlay feed-badge overlay-left">
                <LikeButton
                  class="btn-feed-like"
                  :status="contentDetail.feed.liked"
                  :count.sync="contentDetail.feed.likeCount"
                  :contentId="contentDetail.feed.feedId"
                  :contentName="contentDetail.feed.title"
                />
                <span v-if="contentDetail.feed.likeCount">{{ contentDetail.feed.likeCount }}</span>
              </VueBadge>
              <VueBadge :radius="20" class="overlay feed-badge overlay-right">
                {{ contentDetail.feed.category.categoryText }}
              </VueBadge>
            </div>
          </BrandMediaContentBox>
          <div class="feed-title-container">
            <div class="feed-item-title">{{ contentDetail.feed.title }}</div>
            <div class="feed-item-date">{{ contentDetail.feed.publishDate | parseISODate }}</div>
          </div>
          <div class="feed-summary-container">
            <div class="feed-item-summary">
              <div
                v-html="contentDetail.htmlText"
                :class="[
                  contentDetail.feed.sourceType === 2 && 'listelist-content',
                  'feed-content',
                ]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-action">
      <BrandButton
        v-if="isFromFeed && contentDetail && !displayCompletionButton"
        @click="goToVirtualVisit"
        :size="sizes.xLarge"
        :contentAlignment="constants.flexAlignment.center"
        class="content-action-btn"
        style="margin-bottom: 20px; background: #D02D2C;"
        >ŞEF DÜKKANINDA İZLE</BrandButton
      >
    </div>
    <div v-if="!!hasDoWinFeed" class="content-action">
      <BrandButton
        v-if="!displayCompletionButton"
        @click="onDoWinCompleted"
        :size="sizes.xLarge"
        :contentAlignment="constants.flexAlignment.center"
        class="content-action-btn"
        >BİTİR</BrandButton
      >
      <div v-else>
        <img
          style="width: 200px;"
          :style="{ 'margin-top': displayCompletionButton ? '25%' : '' }"
          src="~@/assets/icons/checkGradient.svg"
          alt="check"
        />
        <VueText style="margin-top: 30px;" sizeLevel="8" weightLevel="3"
          >Yap Kazanı Tamamladınız</VueText
        >
      </div>
    </div>
  </div>
</template>
<script>
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import VueBadge from '@/components/shared/VueBadge/VueBadge.vue';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import LikeButton from '@/components/brand/BrandButton/LikeButton.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { Content } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import dateUtils from '@/mixins/dateUtils.js';
import { EventLog } from '@/services/Api/index';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import StorageHelper from '@/utils/storageHelper';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';

export default {
  name: 'FeedDetail',
  components: {
    VueBadge,
    BrandMediaContentBox,
    LikeButton,
    BrandVimeoPlayer,
    BrandButton,
    VueText,
  },
  data() {
    return {
      hasDoWinFeed: false,
      displayCompletionButton: false,
      isVisibleAllContent: true,
      contentDetail: null,
      publishDate: null,
      referrerName: 'Bilinmiyor',
      breadcrumbList: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Yaşam',
          link: '/secure/fun',
        },
      ],
      taskFeed: {},
      storageTaskDetail: {},
      isFromFeed: null,
    };
  },
  mixins: [gtmUtils, dateUtils],
  computed: {
    media() {
      let type = '';
      if (this.contentDetail.feed.videoURL) {
        type = MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.embed;
      } else if (this.contentDetail.feed.largeImageURL) {
        type = MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.image;
      }

      let enabled = type != '' ? true : false;
      let content = '';
      if (type == 'embed') {
        content = this.contentDetail.feed.videoURL;
      } else if (type == 'image') {
        content = this.contentDetail.feed.largeImageURL;
      }

      return { type, enabled, content, ratio: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS.by_16_9 };
    },
    propData() {
      const media = {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
      const icon = ICON_VARIABLES.hearth;
      icon.color = '#E5472D';
      return { media, icon };
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  methods: {
    goToVirtualVisit() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`);
    },
    onDoWinCompleted() {
      this.displayCompletionButton = !this.displayCompletionButton;
      this.isVisibleAllContent = !this.isVisibleAllContent;
      window.scrollTo(0, 0);
    },
    routeTo(idx) {
      if (this.breadcrumbList[idx].link) this.$router.push(this.breadcrumbList[idx].link);
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from && from.name) {
        if (from.name === 'feed') {
          vm.referrerName = 'Anasayfa';
        } else if (from.name === 'fun') {
          vm.referrerName = 'Yaşam';
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('app/clearFeedTaskDetail');
    new StorageHelper({ id: 'FeedTaskDetail' }).set({});
    next();
  },
  created() {
    this.taskFeed = this.$store.getters['app/getFeedTaskDetail'];
    this.storageTaskDetail = new StorageHelper({ id: 'FeedTaskDetail' }).get();
    this.isFromFeed = (this.storageTaskDetail?.isFromFeed || this.taskFeed?.isFromFeed) ?? false;
    Content.getFeedContent(this.$route.params.id, this.isFromFeed)
      .then(res => {
        if (res && res.data) {
          const {
            Data: { contentDetail },
          } = res.data;

          this.contentDetail = contentDetail;
          this.hasDoWinFeed = contentDetail.feed.hasYk;
          if (this.contentDetail) {
            this.pushDataLayerEvent('contentView', {
              ...contentDetail.feed,
              pageName: this.referrerName,
            });

            let contentPath = `Yaşam/${contentDetail.feed.category.parentCategory?.categoryText}/${contentDetail.feed.category.categoryText}/${contentDetail.feed.title}`;
            EventLog.postEventLog(contentPath, window.location.href);
            if (window.insider_object) {
              if (window.insider_object.user?.custom) {
                window.insider_object.user.custom.feedDetail = {
                  category: contentDetail.feed.category.parentCategory?.categoryText,
                  subCategory: contentDetail.feed.category.categoryText,
                  feed: contentDetail.feed.title,
                  date: contentDetail.feed.publishDate,
                };
              }
            }

            window.dataLayer
              .slice()
              .reverse()
              .find(i => i.event === 'commercial_data').page_path =
              'Yaşam / ' +
              contentDetail.feed.category.parentCategory?.categoryText +
              ' / ' +
              contentDetail.feed.category.categoryText +
              ' / ' +
              contentDetail.feed.title;

            this.breadcrumbList.push(
              {
                name: contentDetail.feed.category.parentCategory?.categoryText,
                link: `/secure/fun?contentTypeId=${contentDetail.feed.category.parentCategory?.categoryId}`,
              },
              {
                name: contentDetail.feed.category.categoryText,
                link: `/secure/fun?contentTypeId=${contentDetail.feed.category.categoryId}`,
              },
              {
                name: contentDetail.feed.title.toLowerCase(),
              },
            );
          }
        }
      })
      .catch(err => console.error(err));
  },
};
</script>

<style scoped lang="scss">
$overlay-side-space: palette-space-level(20);
$space-10: palette-space-level(10);
$space-30: palette-space-level(30);

.feed-top-video-container {
  width: 100%;
  .feed-image-bottom {
    width: 100%;
    height: 90px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .overlay {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    padding: palette-space-level(8) 9px;
    display: flex;
    font-size: palette-font-size-level(5);
    font-weight: palette-font-weight-level(3);

    &.overlay-left {
      padding: 3px;
      /* left: $overlay-side-space;
      svg {
        height: 16px;
      } */
      span {
        padding-left: palette-space-level(10);
        padding-right: 10px;
      }
    }
    &.overlay-right {
      right: $overlay-side-space;
      float: right;
    }
  }
}
.feed-media {
  position: relative;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  cursor: auto;

  &:before {
    content: '';
    display: block;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .feed-image-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(153, 153, 153, 0.6) 98%
    );
  }
  .overlay {
    padding: 10px;
    display: flex;
    font-size: palette-font-size-level(5);
    font-weight: palette-font-weight-level(3);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);

    &.overlay-left {
      left: $overlay-side-space;
      padding: 3px;
      svg {
        height: 16px;
      }
      span {
        padding-left: palette-space-level(10);
        padding-right: 10px;
      }
    }
    &.overlay-right {
      right: $overlay-side-space;
      float: right;
    }
  }
}

.feed-item-container {
  margin-bottom: palette-space-level(15);
  .video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    .video-frame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .feed-summary-container {
    display: flex;
    justify-content: space-between;
    padding: 60px 20px 20px;
    padding-right: 0;
  }
  .feed-item-summary {
    padding-right: 90px;
    width: 100%;
  }
  .feed-item-summary {
    div,
    span,
    h2,
    p {
      padding-bottom: $space-10;
    }
    .feed-item-content {
      color: $brand-link-primary-hover-color;
    }
  }
  .feed-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-30 $overlay-side-space;
    border-bottom: 0.5px solid rgba(palette-color-level('grey', '20'), 0.6);

    .feed-item-title {
      font-size: palette-font-size-level(9);
      font-weight: palette-font-weight-level(3);
    }
    .feed-item-date {
      color: palette-color-level(grey, 30);
    }
  }

  .feed-badge {
    display: flex;
    align-items: center;
    bottom: 25px;
  }
}
.btn-feed-like {
  height: 34px;
  cursor: pointer;
  /deep/ .like-buttons-container {
    left: -3px;
    top: -50px;
  }
}

/deep/ img {
  border: none !important;
}
/deep/ .feed-content {
  img {
    border: none !important;
    border-radius: palette-space-level(3);
  }
  * {
    width: 100%;
    word-break: break-word;
    height: auto;
  }
}
.content-action {
  text-align: -webkit-center;
  .content-action-btn {
    width: 70% !important;
  }
}

.feedback-icons {
  display: flex;
  margin-bottom: 5px;
  .feedback-icon {
    width: auto;
    height: 14px;
    margin-right: 5px;
  }
  .feedback-count {
    font-size: 12px;
  }
}
/deep/ .listelist-content {
  img {
    width: auto !important;
    max-width: 100%;
    margin-bottom: 20px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    padding-bottom: 0 !important;
    padding-top: 10px;
    line-height: 1.25;
  }
  h1 {
    font-size: 2em;
    margin-bottom: 0.67em;
  }
  h2 {
    font-size: 1.5em;
    margin-bottom: 0.83em;
  }
  h3 {
    font-size: 1.17em;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 1em;
    margin-bottom: 1.33em;
  }
  h5 {
    font-size: 0.83em;
    margin-bottom: 1.33em;
  }
  h6 {
    font-size: 0.67em;
    margin-bottom: 2.33em;
  }
  p {
    margin-bottom: 20px;
    padding-bottom: 0 !important;
    line-height: 1.44;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-bottom: 1em;
    padding-left: 40px;
    list-style: square;
    li {
      margin-bottom: 0.5em;
    }
  }
}
</style>
